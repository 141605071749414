import '../App.css';
import React, { useEffect, useState } from "react";
import {
  Container,
  MenuItem,
  MenuItemLink,
} from "./Footer.elements";





import { BsFillTelephoneForwardFill } from "react-icons/bs";
import { GrMail } from "react-icons/gr";

const Footer = () => {

  const [showMobileMenu, setShowMobileMenu] = useState(false);

  function useWindowDimensions() {

    const hasWindow = typeof window !== 'undefined';

    function getWindowDimensions() {
      const width = hasWindow ? window.innerWidth : null;
      const height = hasWindow ? window.innerHeight : null;
      return {
        width,
        height,
      };
    }

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
      if (hasWindow) {
        function handleResize() {
          setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }
    }, [hasWindow]);

    return windowDimensions;
  }

  const { height, width } = useWindowDimensions();

  const [esconder, setEsconder] = useState("block")

  


  useEffect(() => {
    if (width < 700) {
      setEsconder("none")
    } else {
      setEsconder("block")
    }
  }, [width])

  



  return (
    <div style={{ backgroundColor: "#23394D" }} className="fixed-bottom d-flex flex-lg-row flex-column justify-content-around" >
      <span className="text-uppercase" style={{ color: "#62AEF9" }} >
        <span style={{ display: esconder }} > derechos reservados </span>
        &copy; qualitytransferspv
      </span>
      <div >
        <span style={{ display: esconder, color: "rgb(229 29 29)" }}  > Call us</span>  <span style={{ color: "#62AEF9" }} > <BsFillTelephoneForwardFill style={{ color: "rgb(229 29 29)" }} /> +52 322 123 6676</span>

      </div>
      <div>
        <span style={{ display: esconder, color: "rgb(229 29 29)" }} >Send us an email </span>  <span style={{ color: "#62AEF9" }} > <GrMail style={{ color: "rgb(229 29 29)" }} /> info@qualitytransferspv.com </span>
      </div>

    </div >
  );
};

export default Footer;
