import React from 'react'
import '../../App.css';

import { useParams } from 'react-router-dom'
import { useState } from 'react';
import { useEffect } from 'react';



export const Precios = ({ id, lugar, precio }) => {


  const params = useParams()
  // console.log(params.id)

  const [estilo, setEstilo] = useState('')

  // console.log(params)

  useEffect(() => {
    if (id % 2 == 0) {
      setEstilo('bg-table-two')
    } else {
      setEstilo('bg-table-one')
    }
  }, [id, precio])


  return (

    <>    
      <tr className={estilo}>
        <td scope="row" className="border-table-right text-capitalize"> {lugar} </td>
        <td className="border-table-left">{precio} USD </td>
      </tr>
    </>



  )
}
