import React from 'react'
import '../App.css';

import destinos from '../Fotos/publicidad/destinos.jpg'
import publicidad01 from '../Fotos/publicidad/publicidad01.jpg'

import { BsFillTelephoneForwardFill } from "react-icons/bs";
import { GrMail } from "react-icons/gr";



export const Destinations = () => {
  return (
    <div>
      <h2 id="Destinations" className="title-secondary espaciados col-12 text-center display-4 shadow-title">
        DESTINATIONS, TOURS, DAY TRIP
      </h2>

      <section className="d-flex flex-column justify-content-between">

        <div className="row mb-5 ">
          <p className="text-justify text-destinos mt-3 mb-3 col-12">
            We take you where you want to go! Any hotel, Airbnb or any area in Mexico.
          </p>
          <div className="col-12" >
            <p className="d-flex flex-column col-5">
              <BsFillTelephoneForwardFill /> Call us <span style={{ color: "rgb(229 29 29)" }} >+52 322 123 6676</span>
              <BsFillTelephoneForwardFill className="mt-3" /> Call us <span style={{ color: "rgb(229 29 29)" }} >+52 322 180 9116</span>
            </p>
            <p className="col-5">
              <GrMail /> Send us an email <span style={{ color: "rgb(229 29 29)" }} > info@qualitytransferspv.com </span>
            </p>
          </div>



          <img src={destinos} alt="destino" className="img-fluid col-lg-7  " />
          <div className="col-lg-4 offset-lg-1">

            <img src={publicidad01} alt="publicidad01" className="img-fluid" />
          </div>
        </div>

      </section>

    </div>
  )
}
