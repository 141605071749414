import React from 'react'
import smallSprinter from './Carros/small-sprinter.jpg'
import '../../App.css';

import { BsFillTelephoneForwardFill } from "react-icons/bs";
import { GrMail } from "react-icons/gr";

export const SmallSprinter = () => {
  return (
    <div>
      <h6 className="camuflaje display-4">
        shuttle
      </h6>

      <section className="espaciados">
        <h3 className="title-secondary text-center display-4 shadow-title">
          SMALL SPRINTER
        </h3>
        <p className="">
          Reservations can be made through chat, whatsapp, mail or by phone.
          <br />
          <br />
          <p className="d-flex flex-column">
            <BsFillTelephoneForwardFill /> Call us <span style={{ color: "rgb(229 29 29)" }} >+52 322 123 6676</span>
            <BsFillTelephoneForwardFill className="mt-3" /> Call us <span style={{ color: "rgb(229 29 29)" }} >+52 322 180 9116</span>
          </p>
          <p>
            <GrMail /> Send us an email <span style={{ color: "rgb(229 29 29)" }} > info@qualitytransferspv.com </span>
          </p>
          All prices shown below are taking the starting point from the Puerto Vallarta Jalisco Airport. If you need
          another starting point like a Hotel, Airbnb, or some other place. Chat with us, call us by phone or send us an
          email. We are at your complete disposal !!!
          <br />
          <br />
          <span className="text-azulito text-capitalize">
            Payments
          </span>
          can be made with cash or any debit/credit card. These are done directly with your assigned driver on your
          arrival.
        </p>
        <div className="row mt-4 mb-4">
          <img className="col-lg-6 img-cars" src={smallSprinter} alt="small sprinter" />
          <div className=" offset-lg-2 col-lg-4 d-flex flex-column justify-content-around">
            <h4>
              1-12 PEOPLE
            </h4>
            <h4>
              DEPARTING FROM PUERTO VALLARTA JALISCO AIRPORT
            </h4>
            <h4 className="border-precio-especial p-2 text-justify">
              <span className="">
                50 USD PER HOUR (MINIMUM 3 HRS)
              </span>
              <hr />
              <span className="">
                500 USD ALL DAY (12 HRS)
              </span>
              <hr />
              <span className="text-uppercase">
                BOTH PRICES ONLY APPLY WITHIN THE CITY OF PUERTO VALLARTA, LIKE EVENTS, TOURS, TOURS TO RESTAURANTS.
                INCLUDES DRIVER, BEER AND WATER.
              </span>
            </h4>
          </div>
        </div>

        

      </section>
    </div>
  )
}
