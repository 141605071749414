import './App.css';
import { HashRouter as Router, Switch, Route, Redirect, Link } from "react-router-dom"
import { Home } from './components/Home';
import { Destinations } from './components/Destinations';
import { Comments } from './components/Comments';
import { Prices } from './components/Prices';
import { Sedan } from './components/cars/Sedan';
import { Van } from './components/cars/Van';
import { Suv } from './components/cars/Suv';
import { SmallSprinter } from './components/cars/SmallSprinter';
import { BigSprinter } from './components/cars/BigSprinter';
import { Contact } from './components/Contact';

import Navbar from './components/Navbar'


import { useEffect, useState } from 'react';
import Footer from './components/Footer';
import { Camuflaje } from './components/Camuflaje';


import axios from 'axios'
import { Precios } from './components/cars/Precios';
import { PreciosSedan } from './components/cars/PreciosSedan';
import { PreciosVan } from './components/cars/PreciosVan';
import { PreciosSuv } from './components/cars/PreciosSuv';
import { PreciosSmallSprinter } from './components/cars/PreciosSmallSprinter';
import { PreciosBigSprinter } from './components/cars/PreciosBigSprinter';


function App() {

  const [infoSedan, setInfoSedan] = useState([])
  const [infoVan, setInfoVan] = useState([])
  const [infoSuv, setInfoSuv] = useState([])
  const [infoSmallSprinter, setInfoSmallSprinter] = useState([])
  const [infoBigSprinter, setInfoBigSprinter] = useState([])

  useEffect(() => {
    const func = async () => {
      const res = await axios({
        method: 'GET',
        url: 'sedan/',
        baseURL: 'https://qtpv-precios-dolares.herokuapp.com/'
      })
      setInfoSedan(res.data)
    }
    func()
  }, [])

  useEffect(() => {
    const func = async () => {
      const res = await axios({
        method: 'GET',
        url: 'van/',
        baseURL: 'https://qtpv-precios-dolares.herokuapp.com/'
      })
      setInfoVan(res.data)
    }
    func()
  }, [])

  useEffect(() => {
    const func = async () => {
      const res = await axios({
        method: 'GET',
        url: 'suv/',
        baseURL: 'https://qtpv-precios-dolares.herokuapp.com/'
      })
      setInfoSuv(res.data)
    }
    func()
  }, [])

  useEffect(() => {
    const func = async () => {
      const res = await axios({
        method: 'GET',
        url: 'smallSprinter/',
        baseURL: 'https://qtpv-precios-dolares.herokuapp.com/'
      })
      setInfoSmallSprinter(res.data)
    }
    func()
  }, [])

  useEffect(() => {
    const func = async () => {
      const res = await axios({
        method: 'GET',
        url: 'bigSprinter/',
        baseURL: 'https://qtpv-precios-dolares.herokuapp.com/'
      })
      setInfoBigSprinter(res.data)
    }
    func()
  }, [])

  const listPreciosSedan = infoSedan.map(e => <Precios
    key={e.id}
    id={e.id}
    lugar={e.lugar}
    precio={e.precio}
  />)

  const listPreciosSedanLista = infoSedan.map(e => <PreciosSedan
    key={e.id}
    id={e.id}
    lugar={e.lugar}
    precio={e.precio}
  />)

  const listPreciosVan = infoVan.map(e => <Precios
    key={e.id}
    id={e.id}
    lugar={e.lugar}
    precio={e.precio}
  />)

  const listPreciosSuv = infoSuv.map(e => <Precios
    key={e.id}
    id={e.id}
    lugar={e.lugar}
    precio={e.precio}
  />)

  const listPreciosSmallSprinter = infoSmallSprinter.map(e => <Precios
    key={e.id}
    id={e.id}
    lugar={e.lugar}
    precio={e.precio}
  />)

  const listPreciosBigSprinter = infoBigSprinter.map(e => <Precios
    key={e.id}
    id={e.id}
    lugar={e.lugar}
    precio={e.precio}
  />)

  function useWindowDimensions() {

    const hasWindow = typeof window !== 'undefined';

    function getWindowDimensions() {
      const width = hasWindow ? window.innerWidth : null;
      const height = hasWindow ? window.innerHeight : null;
      return {
        width,
        height,
      };
    }

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
      if (hasWindow) {
        function handleResize() {
          setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }
    }, [hasWindow]);

    return windowDimensions;
  }

  const { height, width } = useWindowDimensions();

  const [margen, setMargen] = useState("3rem")

  useEffect(() => {
    if (width < 500) {
      setMargen("0")
    } else {
      setMargen("3rem")
    }
  }, [width])


  return (
    <Router>
      <Navbar />
      <div style={{ margin: margen }}>
        <Switch>
          <Route path="/home" >
            <Home />
          </Route>
          <Route path="/destinations" >
            <Destinations />
          </Route>
          <Route path="/comments" >
            <Comments />
          </Route>
          <Route path="/prices" >
            <Prices />
          </Route>
          <Route path="/sedan" >
            <Sedan />
            <PreciosSedan />
            <table className="table table-bordered">
              <thead>
                <tr className="bg-table-title">
                  <th scope="col" className="border-table-right">Destinations</th>
                  <th scope="col" className="border-table-left">Prices</th>
                </tr>
              </thead>
              <tbody>
                {listPreciosSedan}
              </tbody>
            </table>
          </Route>
          <Route path="/van" >
            <Van />
            <PreciosVan />
            <table className="table table-bordered">
              <thead>
                <tr className="bg-table-title">
                  <th scope="col" className="border-table-right">Destinations</th>
                  <th scope="col" className="border-table-left">Prices</th>
                </tr>
              </thead>
              <tbody>
                {listPreciosVan}
              </tbody>
            </table>
          </Route>
          <Route path="/suv" >
            <Suv />
            <PreciosSuv />
            <table className="table table-bordered">
              <thead>
                <tr className="bg-table-title">
                  <th scope="col" className="border-table-right">Destinations</th>
                  <th scope="col" className="border-table-left">Prices</th>
                </tr>
              </thead>
              <tbody>
                {listPreciosSuv}
              </tbody>
            </table>
          </Route>
          <Route path="/small-sprinter" >
            <SmallSprinter />
            <PreciosSmallSprinter />
            <table className="table table-bordered">
              <thead>
                <tr className="bg-table-title">
                  <th scope="col" className="border-table-right">Destinations</th>
                  <th scope="col" className="border-table-left">Prices</th>
                </tr>
              </thead>
              <tbody>
                {listPreciosSmallSprinter}
              </tbody>
            </table>
          </Route>
          <Route path="/big-sprinter" >
            <BigSprinter />
            <PreciosBigSprinter />
            <table className="table table-bordered">
              <thead>
                <tr className="bg-table-title">
                  <th scope="col" className="border-table-right">Destinations</th>
                  <th scope="col" className="border-table-left">Prices</th>
                </tr>
              </thead>
              <tbody>
                {listPreciosBigSprinter}
              </tbody>
            </table>
          </Route>
          <Route path="/contact" >
            <Contact />
          </Route>
          <Route path="/" >
            <Redirect to="/home" />
          </Route>
        </Switch>




      </div>
      <Camuflaje />
      <Footer />
    </Router>
  );
}

export default App;
