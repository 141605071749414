
import '../App.css';

import imagen01 from '../Fotos/imagen01.jpg'
import imagen02 from '../Fotos/imagen02.jpg'
import imagen03 from '../Fotos/imagen03.png'

import { Slide } from './Slide';
import { BsFillTelephoneForwardFill } from "react-icons/bs";
import { GrMail } from "react-icons/gr";

export const Home = () => {

  return (
    <div>
      <h6 class="camuflaje-home display-4">
        shuttle
      </h6>

      <h1 className="text-uppercase  title-principal text-center mb-3 display-1 shadow-title ">
        quality transfers pv
      </h1>

      <div className='row mb-5' >
        <div className="col-lg-6" >

          <div className='d-flex flex-lg-row flex-column justify-content-between '>
            <img src={imagen02} alt={"pick up people"} className="col-lg-8" />
            <p className="col-lg-4" >
              All our clients are received with a sign regarding their name on it!
            </p>
          </div>
        </div>
        <div className="col-lg-6" >
          <div className='d-flex flex-lg-row flex-column justify-content-between '>
            <img src={imagen01} alt={"pick up people"} className="col-lg-8" />
            <p className="col-lg-4" >
              A requested of a client, we would be pleased to receive happy clients!
            </p>
          </div>
        </div>


      </div>


      <p>
        We are open daily
        <span className="text-azulito">
          24/7
        </span>.
        We are at your complete disposal.
      </p>
      <p className="d-flex flex-column">
        <BsFillTelephoneForwardFill /> Call us <span style={{ color: "rgb(229 29 29)" }} >+52 322 123 6676</span>
        <BsFillTelephoneForwardFill className="mt-3" /> Call us <span style={{ color: "rgb(229 29 29)" }} >+52 322 180 9116</span>
      </p>
      <p>
        <GrMail /> Send us an email <span style={{ color: "rgb(229 29 29)" }} > info@qualitytransferspv.com </span>
      </p>
      <p>
        We offer the best ground transportation and the best tours in Puerto Vallarta.
      </p>
      <p>
        We are a private transportation company in Puerto
        Vallarta that is worth to hire, we will make you feel at
        home, with our service you will be convinced of the
        value we have, thank you for your trust, you will not
        regret traveling with us!
      </p>

      <Slide />


      <img src={imagen03} alt={"cars"} className="mt-3 img-fluid" />    


    </div>
  )
}
