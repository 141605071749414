import React from 'react'
import '../../App.css';


import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios'



export const PreciosSuv = () => {



  const [search, setSearch] = useState("")
  const [info, setInfo] = useState([])
  const [precio, setPrecio] = useState(null)

  


  useEffect(() => {
    const func = async () => {
      const res = await axios({
        method: 'GET',
        url: 'suv/',
        baseURL: 'https://qtpv-precios-dolares.herokuapp.com/'
      })
      setInfo(res.data)
    }
    func()
  }, [])


  const list = info.map(e => (<option key={e.id} value={e.id} lugar={e.lugar} precio={e.precio} >{e.lugar}</option>))


  useEffect(() => {
    for (let i = 0; i < list.length; i++) {
      const busqueda = list[i];
      if (busqueda.key == search) {
        setPrecio(busqueda.props.precio)
      }
    }
  }, [search])




  return (

    <div>
      < select className="form-control mb-3 " onChange={e => setSearch(e.target.value)}>
        <option value="" >Choose a destination </option>
        {list}
      </select >
      <h4 className='text-center mb-3' > {precio} USD </h4>
    </div>

  )
}