import React from 'react'

export const Camuflaje = () => {
  return (
    <div>
      <h6 class="camuflaje-home display-4">
        shuttle
      </h6>
    </div>
  )
}
